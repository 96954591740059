<template>
  <div class="wrapper">
    <img class="order-img" src="/img/order_top.png" alt="">
    <!--收货地址-->
    <div class="user-address-box flex ai-c jc-sb ai-c" @click="$router.push('/my/addressList')">
        <div class="address-detail">
          <div class="user-basic-info flex ai-c jc-sb">
            <div class="user-name">我的名字</div>
            <div class="user-phone">1775*****26</div>
          </div>
          <div class="user-address flex-box">
                <div class="address lines">
                    厦门湖里区
                </div>
          </div>
        </div>
    </div>
    <!--订单列表-->
    <div class="goods-item">
        <div class="goods-shop">
            <img src="/img/icon-shop.png" alt="">
            i团团云供应链
        </div>
        <div class="list">
            <div class="goods flex" v-for="(item, index) in 3" :key="index">
                <img src="/img/goods.png" alt="">
                <div class="">
                    <div class="flex ai-bl">
                        <div class="name line">南极人全棉床垫床褥1.8m床...</div>
                        <div class="price">
                            ¥189.<font>10</font>
                        </div>
                    </div>
                    <div class="flex ai-c jc-sb" style="margin-top:.1rem">
                        <div class="classification line">5件套（3刀1剪1刀座）</div>
                        <div class="nb">x1</div>
                    </div>
                    <div class="fw">7天无理由退换</div>
                </div>
            </div>
        </div>
        <div class="subtotal flex ai-c jc-fd">
            <div class="subtotal-zl">共2件商品</div>
            <div class="subtotal-lb">小计：</div>
            <div class="subtotal-price">¥278.<font>10</font></div>
        </div>
    </div>
    <div class="information copyTxt" @click="copy" data-clipboard-text="1">
        <div class="title">订单信息</div>
        <div class="txt flex ai-c jc-sb">订单编号：7800008886666 <div class="b">复制</div></div>
        <div class="txt">订单时间：2018-10-10 10:56:12</div>
    </div>
    <v-loveGoodsList />
    <div class="kong"></div>
    <div class="btn-box flex ai-c jc-sb">
        <div class="go-home flex fd-c ai-c jc-c" @click="$router.push('/home')">
            <img src="/img/icon-home.png" alt="">
            商城
        </div>
        <div class="flex">
            <div class="btn">加入购物车</div>
            <div class="btn">确认收货</div>
        </div>
    </div>
  </div>
</template>

<script>
import loveGoodsList from '@/components/loveGoodsList'
export default {
  components:{
      'v-loveGoodsList': loveGoodsList
  },
  props: {},
  data() {
    return {
        changeAddress: {},
        defaultAddress: true
    };
  },
  watch: {},
  computed: {},
  methods: {
    copy() {
      var clipboard = new this.Clipboard(".copyTxt");
      clipboard.on("success", (e) => {
        // 释放内存
        this.$toast.success("复制成功!");
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.$dialog
          .alert({
            message: "该浏览器不支持自动复制",
          })
          .then(() => {
            // on close
          });
        // 释放内存
        clipboard.destroy();
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.wrapper {
}
.order-img{
    width: 100%;
    display: block;
}
.user-address-box{
  width: 100%;
  padding: .20rem 0.1rem .2rem .30rem;
  background: #fff;
  margin-bottom: .25rem;
  box-sizing: border-box;
}
.no-address{
  width: 100%;
  margin: .30rem;
  margin-left: 0;
  position: relative;
  font-size:.32rem;
  font-weight:bold;
}
.address-detail{
  width: 6.5rem;
}
.user-basic-info{
  width: 100%;
  margin-bottom: .15rem;
}
.user-name,.icon-box{
  font-size: .32rem;
  width: 30%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-phone,.address{
  font-size: .32rem;
  width: 65%;
}
.address{
  width: 90%;
}
.default{
  padding: 0 .10rem;
  color: #D54848;
  font-size: .22rem;
  border: 1px solid #E58A8A;
  border-radius: .08rem;
}
.address{
  font-size: .26rem;
}
.goods-item{
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    margin-top: .2rem;
    padding-bottom: .4rem;
}
.goods-item .goods-shop{
    font-size: .26rem;
    font-family: PingFang;
    font-weight: bold;
    color: #61626A;
    margin-left: .3rem;
    margin-top: .3rem;
}
.goods-item .goods-shop img{
    width: .22rem;
    height: .2rem;
    margin-right: .1rem;
}
.list{
    margin-top: .1rem;
}
.list .goods{
    width: 100%;
    box-sizing: border-box;
    padding: 0.3rem .3rem;
}
.list .goods img{
    width: 1.54rem;
    height: 1.54rem;
    margin-right: .3rem;
}
.list .goods .name{
    width: auto;
    /* width: 3.90rem; */
    font-size: .30rem;
    font-family: PingFang;
    font-weight: 500;
    color: #61626A;
    margin-right: .16rem;
}
.list .goods .price{
    white-space: nowrap;
    font-size: .28rem;
    font-family: PingFang;
    font-weight: 500;
    color: #61626A;
}
.list .goods .price font{
    font-size: .24rem;
}
.list .goods .classification{
    max-width: 3.5rem;
    height: .38rem;
    padding: 0 .05rem;
    line-height: .38rem;
    box-sizing: border-box;
    background: #F4F4F4;
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #AAAAAA;
}
.list .goods .nb{
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #AAAAAA;
}
.list .goods .fw{
    width: fit-content;
    height: .28rem;
    line-height: .28rem;
    padding: 0 0.05rem;
    background: #F9EFED;
    font-size: .20rem;
    font-family: PingFang;
    font-weight: 500;
    color: #F6593F;
    margin-top: .4rem;
}

.subtotal{
    margin-top: .5rem;
}
.subtotal-zl{
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #AAAAAA;
}
.subtotal-lb{
    font-size: .30rem;
    font-family: PingFang;
    font-weight: 500;
    color: #61626A;
    margin-left: .15rem;
}
.subtotal-price{
    font-size: .30rem;
    font-family: PingFang;
    font-weight: 800;
    color: #F35E3A;
    margin-right: .38rem;
}
.subtotal-price font{
    font-size: .28rem;
}
.kong{
    width: 100%;
    height: 2.6rem;
}
.btn-box{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.4rem;
    background-color: #fff;
    padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
    padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
}
.btn-box .go-home{
    font-size: .26rem;
    font-family: PingFang;
    font-weight: 500;
    color: #61626A;
    margin-left: .36rem;
}
.btn-box .go-home img{
    width: .36rem;
    height: .36rem;
}
.btn-box .btn{
    width: 2rem;
    height: .8rem;
    line-height: .8rem;
    text-align: center;
    background: #F35E3A;
    border-radius: .4rem; 
    font-size: .30rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FFFFFF;
    margin-right: .3rem;
}
.btn-box .btn{
    width: 1.7rem;
    height: 0.70rem;
    line-height: .7rem;
    text-align: center;
    background: #FDFEFF;
    border: .02rem solid #61626A;
    border-radius: .35rem;
    margin-right: .27rem;
    font-size: .28rem;
    font-family: PingFang;
    font-weight: 500;
    color: #61626A;
}
.btn-box .btn:nth-last-child(1){
    color: #F35E3A;
    border-color: #F35E3A;
}
.information{
    margin-top: .2rem;
    width: 100%;
    padding: .4rem .3rem .5rem;
    box-sizing: border-box;
    background-color: #fff;
}
.information .title{
    font-size: .30rem;
    font-family: PingFang;
    font-weight: 500;
    color: #282828;
}
.information .txt{
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #61626A;
    margin-top: .22rem;
}
.information .txt .b{
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #F35E3A;
}
</style>